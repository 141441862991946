import Layout from '@components/layout';
import BreadcrumbsNav from '@components/partials/common/breadcrumbsNav';
import SplitIntroText from '@components/partials/common/introTextSplit';
import Pagination from '@components/partials/common/navigation/pagination';
import Carousel from '@components/partials/default/carousel';
import FlexPagination from '@components/partials/listPage/FlexPagination';
import SortTabs from '@components/partials/listPage/sortTabs';
import BreadcrumbList from '@components/partials/schemas/breadcrumbList';
import CIDSchema from '@components/partials/schemas/cidSchema';
import { getHolidayInfo } from '@data/holiday';
import styles from '@styles/list-page-scss/_listPage.module.scss';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useCrosslinkParse } from '../../../lib/repository/crossLinkParser';
import { findNextPageSlug, findPrevPageSlug } from '../../../lib/repository/pageFinder';
import { CardDataArrayType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';
import { paginationLimit } from '../../../lib/web/config';
import { getCanonicalUrl } from '../../../lib/web/meta';

type Props = {
    holidayMenuContent: holidayProps;
    categoryTopApiData: {
        results: CardDataArrayType;
        categoryInfo: [
            {
                name: string;
                intro: string;
                slug: string;
                headerText: string;
                title: string;
                metaKeywords: string;
                metaDescription: string;
                catImage: string;
                numberOfSites: number;
                numberOfActiveSites: number;
                seoSpotA: string;
                seoSpotB: string;
            }
        ];
        onlyfans: [{ id: number; name: string; tourlink: string; thumb: string; displayText: string }];
    };
    queries: any;
    crosslinkJSON: { id: number; slug: string; text: string } & { error: string };
};

export default function CategoryPage({ holidayMenuContent, categoryTopApiData, queries, crosslinkJSON }: Props): JSX.Element {
    const router = useRouter();

    const h1Title = `Best ${categoryTopApiData.categoryInfo[0].name} Porn Sites & Reviews `;
    let metaTitle;

    if (categoryTopApiData.categoryInfo[0].headerText) {
        metaTitle = categoryTopApiData.categoryInfo[0].headerText;
        //replace {number} with the active sites
        metaTitle = metaTitle.replace('{number}', categoryTopApiData.categoryInfo[0].numberOfSites.toString());
        //replace {name} with the name
        metaTitle = metaTitle.replace('{name}', categoryTopApiData.categoryInfo[0].name);
        //replace {year} with the year
        metaTitle = metaTitle.replace('{year}', new Date().getFullYear().toString());
    } else {
        metaTitle = `Top {number} Best ${categoryTopApiData.categoryInfo[0].name} Porn Sites | Rabbits Reviews`;
        metaTitle = metaTitle.replace('{number}', categoryTopApiData.categoryInfo[0].numberOfSites.toString());
    }
    const { title, links } = useCrosslinkParse(crosslinkJSON, categoryTopApiData.categoryInfo[0].name);

    useEffect(() => {
        //This is a special case for when the user clicks on the back button
        //When you click on the back button, the query parameter doesn't get pass down properly
        //So I had to do a manual refresh here for those cases
        //In here we check if there's a discrepancy between how many page queries the server finds
        //vs the client finds. Run a reloads if there's one.
        if (Object.keys(router.query).length > 0 && Object.keys(queries).length === 0) {
            router.reload();
        }
    }, [queries]);

    return (
        <Layout holiday={holidayMenuContent}>
            <Head>
                <title>{metaTitle}</title>
                <meta name="description" content={categoryTopApiData.categoryInfo[0].metaDescription} />
                <meta name="keywords" content={categoryTopApiData.categoryInfo[0].metaKeywords} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={categoryTopApiData.categoryInfo[0].metaDescription} />
                <meta property="og:image" content={categoryTopApiData.categoryInfo[0].catImage} />
                <meta name="robots" content="index, follow" />
                <meta property="og:url" name="title" content={getCanonicalUrl(router)} />
                <link rel="canonical" href={getCanonicalUrl(router)} />
                {/* finds the next page to construct '<link rel="next" href="porn/category/anal?page=${nextPageNumber}"/>' */}
                {Number(router.query.page) !==
                    Math.ceil(Number(categoryTopApiData.categoryInfo[0].numberOfSites / paginationLimit)) &&
                Number(categoryTopApiData.categoryInfo[0].numberOfSites) > paginationLimit ? (
                    <link rel="next" href={findNextPageSlug(router)} />
                ) : (
                    ''
                )}
                {/* finds the next page to construct '<link rel="prev" href="porn/category/anal?page=${prevPageNumber}">' */}
                {router.query.page && Number(router.query.page) > 1 ? <link rel="prev" href={findPrevPageSlug(router)} /> : ''}
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(CIDSchema(categoryTopApiData)) }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                            BreadcrumbList({
                                primary: 'categories',
                                disableSecondary: true
                            })
                        )
                    }}
                />
            </Head>
            <div className={styles.listPage}>
                <BreadcrumbsNav style="cid" primary="categories" disableSecondary={true} />
                <div className={`${styles.listIntro} ${styles.noMarginBottom}`}>
                    <SplitIntroText introTitle={h1Title} introContent={categoryTopApiData.categoryInfo[0].seoSpotA} />
                </div>
                {!categoryTopApiData.onlyfans || categoryTopApiData.onlyfans.length < 3 || Number(router.query.page) > 1 ? (
                    ''
                ) : (
                    <Carousel
                        type="onlyfansCarousel"
                        items={{
                            links: categoryTopApiData.onlyfans,
                            title: `${categoryTopApiData.categoryInfo[0].name} OnlyFans Accounts`
                        }}
                        style={{ type: 'onlyfans' }}
                    />
                )}
                <SortTabs />
                <FlexPagination holiday={holidayMenuContent} cards={categoryTopApiData.results} tag={'category'} />
                <Pagination numberOfSites={categoryTopApiData.categoryInfo[0].numberOfSites} />
                {queries.page > 1 && crosslinkJSON && categoryTopApiData.categoryInfo[0].name ? (
                    ''
                ) : (
                    <Carousel type="tag" items={{ title: title, links }} />
                )}
            </div>
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    // Queries from client-side
    const queries = await context.query;
    const pageQuery = queries && queries.page ? `offset=${(Number(queries.page) - 1) * paginationLimit}` : 'offset=0';

    //default sorting order
    //if a sorting is applied then change the sortQuery to that instead
    const sortQuery = queries && queries.sort ? String(queries.sort) : 'relevance';

    // endpoint with no limit for pagination functionality in list component
    const filteredLinkToFetch =
        process.env.NEXT_PUBLIC_API_DOMAIN +
        `/v1/algolia/categorypage/${context.params.cid}?limit=${paginationLimit}&${pageQuery}&sort=${sortQuery}`;

    const holidayMenuContent = await getHolidayInfo();
    const categoryTopApiHit = await fetch(filteredLinkToFetch);
    const categoryTopApiData = await categoryTopApiHit.json();

    // fetch the content from crosslinks snippet to retrieve the links used in the carousel on the bottom of the page
    const crosslinkEndpoint = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/crosslinks');
    const crosslinkJSON = await crosslinkEndpoint.json();

    // Check if categoryTopApiData is falsy or if there is an error or no categoryInfo
    if (!categoryTopApiData || categoryTopApiData.error || !categoryTopApiData.categoryInfo[0]) {
        return {
            notFound: true
        };
    }

    return {
        props: {
            holidayMenuContent,
            categoryTopApiData,
            queries,
            //When you are in a [cid] page and you open the Instant Search and navigate to another [cid] link
            //for some reason, the states of [cid] weren't resetting, so this issue is found based off of this github
            //discussion https://github.com/vercel/next.js/issues/9992#issuecomment-873225898
            key: context.params.cid,
            crosslinkJSON
        }
    };
};
